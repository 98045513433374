import React from "react";

export const Footer = () => {
  return (
    <div>
      {" "}
      <footer class="footer bg-dark text-light text-center p-5 mt-5">
        Copyright @ Copy; all reserved. Made with💕.
      </footer>
    </div>
  );
};
